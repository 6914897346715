import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  tableTitle: {
    fontWeight: 'medium',
    fontSize: 13,
    paddingBottom: 14,
    color: '#000000',
  },
  innerTableTitle: {
    paddingTop: 14,
    paddingBottom: 0,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 11,
    color: '#212121',
    borderBottomStyle: 'solid',
    borderBottomColor: '#e0e0e0',
    borderBottomWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 8,
  },
  headerRow: {
    fontWeight: 'medium',
  },
  cell: {
    flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  priceCell: {
    flex: 0.5,
  },
  totalRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 14,
    paddingRight: 85,
    color: '#212121',
    fontSize: 11,
    fontWeight: 'medium',
  },
  totalPrice: {
    paddingLeft: 10,
  },
  disclaimerText: {
    fontWeight: 'light',
    paddingTop: 18,
    fontSize: 8,
  },
});
