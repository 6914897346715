import { DamageType, Sight, VehiclePart } from '@monkvision/types';
import { PdfVehicleSide } from '../../utils';
import { TeslaCountry } from '../useTeslaInspectionList/types';

export interface PdfVehicleDetails {
  model: string;
  vin: string;
  inspectionDate: Date | null;
  mileage: number;
  totalCost: number;
  beautyShotUrl: string;
}

export interface PdfPhoto {
  url: string;
  sight: Sight;
}

export interface PdfDamagedPart {
  id: number;
  name: VehiclePart;
  damages: DamageType[];
  repairCost: number;
  croppedPhotoUrls: string[];
  closeUpPhotoUrls: string[];
}

export interface PdfVehicleSideData {
  side: PdfVehicleSide;
  referencePhotoUrl: string;
  damagedParts: PdfDamagedPart[];
}

export interface PdfInteriorDamage {
  area: string;
  damageTypes: string;
  deduction: number;
}

export interface PdfInspectionData {
  inspectionId: string;
  vehicle: PdfVehicleDetails;
  photos: PdfPhoto[];
  damagedSides: PdfVehicleSideData[];
  interiorDamages: PdfInteriorDamage[];
}

export const SIGHT_LABELS_ORDERED = [
  'front-low',
  'front-bumper-side-right',
  'front-fender-right',
  'lateral-full-right',
  'rear-lateral-right',
  'rear-right',
  'rear-low',
  'rear-left',
  'rear-lateral-left',
  'lateral-full-left',
  'front-roof-left',
  'front-bumper-side-left',
  'front-fender-left',
  'tire',
  'front-seats',
  'trunk',
  'keys',
  'dashboard-screen',
];

export const COUNTRIES_WITH_DISCLAMER_TEXT = [TeslaCountry.US];

export const BEAUTY_SHOT_SIGHT_LABEL = 'front-fender-left';

export const SIDE_REFERENCE_SIGHT_LABEL: Record<PdfVehicleSide, string[]> = {
  [PdfVehicleSide.LEFT]: ['lateral-full-left', 'rear-lateral-full-left'],
  [PdfVehicleSide.FRONT]: ['front-low'],
  [PdfVehicleSide.RIGHT]: ['lateral-full-right', 'rear-lateral-full-right'],
  [PdfVehicleSide.REAR]: ['rear-low'],
};

export const DAMAGE_COLORS: Record<DamageType, string> = {
  [DamageType.SCRATCH]: 'rgb(255,46,255)',
  [DamageType.DENT]: 'rgb(55,197,31)',
  [DamageType.SMASH]: 'rgb(0,70,253)',
  [DamageType.BODY_CRACK]: 'rgb(255,56,56)',
  [DamageType.RUSTINESS]: 'rgb(255,157,46)',
  [DamageType.BROKEN_LIGHT]: 'rgb(0,255,248)',
  [DamageType.MISSING_PIECE]: 'rgb(255,243,26)',
  [DamageType.BROKEN_GLASS]: 'rgb(44,94,0)',
  [DamageType.HUBCAP_SCRATCH]: 'rgb(94,0,255)',
  [DamageType.MISSING_HUBCAP]: 'rgb(101,53,0)',
  [DamageType.DIRT]: 'rgb(110,22,22)',
  [DamageType.MISSHAPE]: 'rgb(113,147,222)',
  [DamageType.PAINT_PEELING]: 'rgb(232,148,229)',
  [DamageType.SCATTERED_SCRATCHES]: 'rgb(81,129,48)',
  [DamageType.LIGHT_REFLECTION]: 'rgb(205,255,171)',
  [DamageType.SHADOW]: 'rgb(47,47,47)',
  [DamageType.CAR_CURVE]: 'rgb(0,121,104)',
  [DamageType.PAINT_DAMAGE]: 'rgb(192,26,119)',
};
