import { useCallback, useMemo, useState } from 'react';
import { useLoadingState } from '@monkvision/common';
import { useMonitoring } from '@monkvision/monitoring';
import { FileWithPath } from '@mantine/dropzone';
import { LocalFile, parse, ParseResult, unparse } from 'papaparse';
import dayjs from 'dayjs';
import { MileageUnit } from '@monkvision/types';
import { useCreateTeslaInspectionLink } from '../useCreateTeslaInspectionLink';
import { CreateTeslaInspectionParams } from '../useCreateTeslaInspectionLink/types';
import { TeslaCountry, TeslaModel } from '../useTeslaInspectionList/types';
import { isValidEnumValue } from '../../utils';
import { AppRegion, useAppRegion } from '../../contexts';

export enum InspectionLinkBulkCreateErrorName {
  CSV_PARSE_ERROR = 'csv_parse_error',
  API_REQUEST_ERROR = 'api_request_error',
  UNKNOWN_ERROR = 'unknown_error',
}

interface RowWithVIN {
  vin: string;
  cells: string[];
}

interface CsvRows {
  headers: string[];
  rowsWithVIN: RowWithVIN[];
}

interface ProcessedResults {
  bulkParams: CreateTeslaInspectionParams[];
  csvRows: CsvRows;
}

const BULK_API_REQUEST_DELAY_MS = 500;
const BULK_API_REQUEST_GROUP_START_COUNT = 5;

function processParseResults(
  result: ParseResult<string[]>,
  leaseMaturityDateRequired: boolean,
  licencePlateMandatory: boolean,
): ProcessedResults {
  const headers = result.data[0];
  let [vinHd, countryHd, licencePlateHd, leaseMaturityDateHd, modelHd, odometerHd, mileageUnitHd] =
    headers;
  if (!leaseMaturityDateRequired) {
    [vinHd, countryHd, licencePlateHd, modelHd, odometerHd, mileageUnitHd, leaseMaturityDateHd] =
      headers;
  }
  if (
    vinHd !== 'VIN' ||
    countryHd !== 'Country' ||
    licencePlateHd !== 'Licence Plate' ||
    (leaseMaturityDateRequired && leaseMaturityDateHd !== 'Lease Maturity Date') ||
    modelHd !== 'Model' ||
    odometerHd !== 'Odometer' ||
    mileageUnitHd !== 'Mileage Unit'
  ) {
    const error = new Error('Invalid input CSV format : unknown header columns.');
    error.name = InspectionLinkBulkCreateErrorName.CSV_PARSE_ERROR;
    throw error;
  }
  const bulkParams: CreateTeslaInspectionParams[] = [];
  const rowsWithVIN: RowWithVIN[] = [];
  result.data.forEach((cells, index) => {
    if (index === 0) {
      return;
    }
    let [vin, country, licencePlate, leaseMaturityDate, model, odometer, mileageUnit] = cells;
    if (!leaseMaturityDateRequired && cells.length === 6) {
      [vin, country, licencePlate, model, odometer, mileageUnit, leaseMaturityDate] = cells;
    }
    const odometerValue =
      odometer === '' || odometer === null || odometer === undefined ? undefined : Number(odometer);
    if (
      !vin ||
      !isValidEnumValue(country, TeslaCountry) ||
      (licencePlateMandatory && !licencePlate) ||
      (leaseMaturityDateRequired &&
        (!leaseMaturityDate || !dayjs(leaseMaturityDate, 'YYYY-MM-DD', true).isValid())) ||
      !isValidEnumValue(model, TeslaModel) ||
      (odometerValue !== undefined && Number.isNaN(odometerValue)) ||
      (odometerValue !== undefined && !isValidEnumValue(mileageUnit, MileageUnit))
    ) {
      const error = new Error('Invalid input CSV format : invalid row values.');
      error.name = InspectionLinkBulkCreateErrorName.CSV_PARSE_ERROR;
      throw error;
    }
    bulkParams.push({
      vin,
      country,
      licencePlate,
      leaseMaturityDate: leaseMaturityDateRequired
        ? dayjs(leaseMaturityDate).toDate()
        : dayjs('2025-01-01').toDate(),
      model,
      odometer: odometerValue,
      mileageUnit: mileageUnit as MileageUnit | undefined,
    });
    rowsWithVIN.push({
      vin,
      cells,
    });
  });
  return { bulkParams, csvRows: { headers, rowsWithVIN } };
}

export function useInspectionLinkBulkCreate() {
  const [progress, setProgress] = useState(0);
  const [isParseSuccessful, setIsParseSuccessful] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [csvStr, setCsvStr] = useState<string | null>(null);
  const loading = useLoadingState();
  const { createInspectionLink } = useCreateTeslaInspectionLink();
  const { handleError } = useMonitoring();
  const { region } = useAppRegion();

  const isDownloadAvailable = useMemo(() => !!csvStr, [csvStr]);

  const parseCsvFiles = useCallback((files: FileWithPath[]) => {
    const parsedFileName = files[0].name.substring(0, files[0].name.length - 4);
    return new Promise<{ result: ParseResult<string[]>; fileName: string }>((resolve, reject) => {
      parse<string[], LocalFile>(files[0], {
        skipEmptyLines: true,
        header: false,
        complete: (result: ParseResult<string[]>) => resolve({ result, fileName: parsedFileName }),
        error: (err: unknown) => {
          const error = new Error('Invalid input CSV format : invalid CSV syntax.', { cause: err });
          error.name = InspectionLinkBulkCreateErrorName.CSV_PARSE_ERROR;
          reject(error);
        },
      });
    });
  }, []);

  const createInspectionLinksInBulk = useCallback(
    async (bulkParams: CreateTeslaInspectionParams[]) => {
      setProgress(0);
      let completed = 0;

      const onComplete = () => {
        completed += 1;
        setProgress(Math.round((100 * completed) / bulkParams.length));
      };

      try {
        const links = await Promise.all(
          bulkParams.map(
            (params, index) =>
              new Promise<Record<string, string>>((resolve, reject) => {
                setTimeout(() => {
                  createInspectionLink(params)
                    .then((link) => {
                      onComplete();
                      resolve({ link, vin: params.vin });
                    })
                    .catch(reject);
                }, Math.floor(index / BULK_API_REQUEST_GROUP_START_COUNT) * BULK_API_REQUEST_DELAY_MS);
              }),
          ),
        );
        const linksByVin: Record<string, string> = {};
        links.forEach(({ link, vin }) => {
          linksByVin[vin] = link;
        });
        return linksByVin;
      } catch (err) {
        const error = new Error(
          'An HTTP error occurred during the inspection links bulk creation.',
          { cause: err },
        );
        error.name = InspectionLinkBulkCreateErrorName.API_REQUEST_ERROR;
        throw error;
      }
    },
    [createInspectionLink],
  );

  const createCsvOutput = useCallback((csvRows: CsvRows, linksByVin: Record<string, string>) => {
    const outputRows: string[][] = [[...csvRows.headers, 'Inspection Link']];
    csvRows.rowsWithVIN.forEach(({ vin, cells }) => {
      outputRows.push([...cells, linksByVin[vin]]);
    });
    return unparse(outputRows);
  }, []);

  const generateLinks = useCallback(
    async (files: FileWithPath[]) => {
      loading.start();
      setIsParseSuccessful(false);

      try {
        const { result, fileName: parsedFileName } = await parseCsvFiles(files);
        const { bulkParams, csvRows } = processParseResults(
          result,
          region !== AppRegion.US,
          region !== AppRegion.US,
        );
        setIsParseSuccessful(true);
        const linksByVin = await createInspectionLinksInBulk(bulkParams);
        const unparsedCsvStr = createCsvOutput(csvRows, linksByVin);
        setFileName(parsedFileName);
        setCsvStr(unparsedCsvStr);
        loading.onSuccess();
      } catch (err) {
        loading.onError(err);
        handleError(err);
      }
    },
    [loading, parseCsvFiles, createInspectionLinksInBulk, createCsvOutput],
  );

  const downloadCsv = useCallback(() => {
    if (!csvStr) {
      const error = new Error('CsvStr is not defined!');
      error.name = InspectionLinkBulkCreateErrorName.UNKNOWN_ERROR;
      throw error;
    }
    const blob = new Blob([csvStr], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName ?? 'inspections'}_with_links.csv`;
    a.click();
    a.remove();
  }, [csvStr, fileName]);

  const reset = useCallback(() => {
    setProgress(0);
    setIsParseSuccessful(false);
    setFileName(null);
    setCsvStr(null);
    loading.onSuccess();
  }, []);

  return {
    progress,
    isParseSuccessful,
    isDownloadAvailable,
    generateLinks,
    downloadCsv,
    reset,
    loading,
  };
}
