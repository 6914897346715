import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 3,
  },
  topContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 24,
  },
  detailsContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 12,
  },
  numbersRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  numbersCol: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 32,
  },
  modelText: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#212121',
    paddingBottom: 8,
  },
  text: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#545454',
  },
  label: {
    fontWeight: 'normal',
    color: '#757575',
    fontSize: 12,
    paddingBottom: 6,
  },
  disclaimerText: {
    width: '45%',
    fontWeight: 'light',
    fontSize: 8,
  },
  numberText: {
    fontSize: 16,
  },
  totalCost: {
    fontWeight: 'bold',
  },
  beautyShot: {
    maxHeight: 165,
    maxWidth: '50%',
  },
});
